<!-- =========================================================================================
  File Name: Login.vue
  Description: Login Page
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-1/2">
              <img
                src="@/assets/images/pages/login.png"
                alt="Login"
                class="mx-auto img-responsive img-login"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div class="vx-card__body mt-4 p-0 pt-1">
                  <login-jwt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import LoginJwt from '@/components/login/LoginJWT'

export default {
  components: {
    LoginJwt,
  },
}
</script>
<style lang="scss">
img.img-login {
  max-width: 95%;
}
</style>
